/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import padStart from 'lodash/padStart'
import get from 'lodash/get'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { NewsletterSection } from '@components/NewsletterSection'
import { Hero } from '@components/Hero'
import { Topic } from '@components/Topic'
import { Flex, Box } from '@components/Grid'
import { Text } from '@components/Text'
import { H2, Topline, StrongDarkBackgroundWrapper } from '@components/Heading'
import { Blob } from '@components/Blob'
import { useScrollToElement } from '../../hooks/useScrollToElement'

const randomBlobs = [
  '30% 70% 55% 45% / 38% 30% 70% 62%',
  '36% 64% 55% 45% / 61% 58% 42% 39%',
  '33% 67% 61% 39% / 55% 35% 65% 45%',
  '40% 60% 45% 55% / 42% 35% 65% 58%',
  '47% 53% 57% 43% / 33% 46% 54% 67%',
  '44% 56% 49% 51% / 52% 32% 68% 48%',
]

const TrainingsPage = ({ data: { content }, location }) => {
  // For scroll-to-button
  const targetRef = React.useRef(null)
  const { scrollToTarget } = useScrollToElement()

  return (
    <Layout>
      <SEO
        metadata={{
          title: content.seo.seoTitle,
          description: content.seo.seoDescription,
          pathname: location.pathname,
        }}
      />
      <Hero.Background
        gradient="linear-gradient(0, hsla(0, 0%, 0%, 0.8), hsla(0, 0%, 0%, 0.8))"
        image={content.hero.backgroundImage.asset.fluid}
      >
        <Hero.Shape shape="wide" color="hsl(214,100%,97%)" />
        <Hero>
          <StrongDarkBackgroundWrapper>
            <Hero.Topline color="white">
              {content._rawHero.topline}
            </Hero.Topline>
            <Hero.Heading color="white" content={content._rawHero.heading} />
            <Hero.Text color="white" mb={8} content={content._rawHero.text} />
            <Hero.Button
              mb={8}
              onClick={() => scrollToTarget(targetRef.current)}
            >
              {content._rawHero.cta.label}
              {content._rawHero.cta.showArrow && <Hero.Button.Arrow />}
            </Hero.Button>
          </StrongDarkBackgroundWrapper>
        </Hero>
      </Hero.Background>
      <Box
        as="section"
        flexDirection="column"
        alignItems="center"
        bg="sectionBackground.light"
        px={[5, 6, 7]}
        py={[7, 8, 9]}
      >
        <Topline textAlign="center">{content.secondSectionTopline}</Topline>
        <Box maxWidth="800px" mx="auto">
          <H2
            content={content._rawSecondSectionHeading}
            textAlign="center"
            mb={[6, 7]}
          />
        </Box>
        <Box
          display="grid"
          maxWidth="1000px"
          mx="auto"
          gridTemplateColumns={[
            '100%',
            null,
            'repeat(auto-fit, minmax(300px, 1fr))',
          ]}
          gridGap={7}
        >
          {content.secondSectionList.map((item, index) => (
            <Flex key={item} flexDirection="row">
              <Box>
                <Blob
                  bg="blue.400"
                  width="32px"
                  pb="32px"
                  mr={4}
                  borderRadius={randomBlobs[index % randomBlobs.length]}
                  css={{
                    background:
                      'linear-gradient( 210deg, hsl(216, 50%, 89%), hsl(218, 37%, 80%))',
                  }}
                />
              </Box>
              <Text color="blue.800">{item}</Text>
            </Flex>
          ))}
        </Box>
      </Box>

      <Box ref={targetRef}>
        {content.topics.map((topic, index) => {
          const image = get(topic, 'image.asset.fluid', null)
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Topic
              key={topic._key}
              blobType={topic.blobType}
              variant={topic.variant}
              image={image}
            >
              <Topic.Topline>{`${padStart(
                index + 1,
                2,
                '0'
              )} — Training`}</Topic.Topline>
              <Topic.Heading>{topic.title}</Topic.Heading>
              <Topic.Body>
                {topic.description ? (
                  <Topic.Description
                    content={topic.description}
                    fontWeight="medium"
                  />
                ) : null}
                {topic.contentList.length ? (
                  <Topic.List>
                    {topic.contentList.map((item, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Topic.Item key={`${item}-${i}`}>{item}</Topic.Item>
                    ))}
                  </Topic.List>
                ) : null}
              </Topic.Body>
            </Topic>
          )
        })}
      </Box>

      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query TrainingsPageQuery {
    content: sanityTrainingsPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHero
      secondSectionTopline
      _rawSecondSectionHeading
      secondSectionList
      topics {
        _key
        variant
        title
        _rawDescription(resolveReferences: { maxDepth: 10 })
        contentList
        blobType
        image {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      hero {
        backgroundImage {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default TrainingsPage
